
export default {
  name: 'WebAuthnConditionalComponent',
  data: () => ({
    webAuthnSupported: false,
    webAuthnSupportWait: true
  }),
  mounted () {
    import('@simplewebauthn/browser')
      .then((imports) => {
        const { browserSupportsWebAuthn } = imports
        this.webAuthnSupported = browserSupportsWebAuthn()
        this.webAuthnSupportWait = false
      })
  }
}
