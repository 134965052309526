
export default {
  name: 'MiniAlertComponent',
  props: {
    errorLevel: {
      type: String,
      default: 'danger'
    }
  },
  methods: {
    alertDismissed () {
      this.$emit('mini-alert-dismissed')
    }
  }
}
